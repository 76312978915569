
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class HintModal extends Vue {
  // props

  @Prop({ default: "Hint-Modal" }) id!: string;
  @Prop({ default: "Modal" }) title!: string;
  @Prop({ default: "lg" }) size!: string;
  @Prop({ default: false }) isLoading!: boolean;
  @Prop({ default: true }) showClose!: boolean;
  // data
  isShow: null | boolean = null;
  

  // methods
  onSaveClick(): void {
    this.$emit("onSaveClick");   
  }
  resetModal(): void {
    this.$emit("resetModal");
  }
  hideModal(): void {
    this.$emit("hideModal");
  }
}
